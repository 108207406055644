<template>
<b-container>
  <b-overlay rounded="sm" :show="show">
    <b-row class="mb-2 justify-content-end">
      <b-col  md="8" lg="6">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Introduzca busqueda..." v-model="search" @keyup.enter="searchWork()" >
          <div class="input-group-append">
            <button class="btn btn-success" @click="searchWork()" type="button"><feather-icon icon="SearchIcon" /></button>
            <button class="btn btn-primary" @click="searchReset()" type="button"><feather-icon icon="XIcon"/></button>
          </div>
        </div>
      </b-col>
    </b-row>  
    <b-row>
      <b-col lg="6" v-for="item, index in list" :key="index">  
        <b-card no-body class="overflow-hidden visita">          
          <b-card-header :class="'bg-' + status[item.status].class + ' text-white'">
            <b-card-title class="text-white">{{ item.doc_num }} <small>( {{ formatDate(item.date_command) }} )</small>  <br><small>{{ status[item.status].text }}</small></b-card-title>
            <b-button class="mr-1 btn-sm" v-if="item.status == 1" variant="success" id="accept" @click="changeStatus(item.id,2)">Aceptar</b-button>
            <b-button class="mr-1 btn-sm" v-if="item.status == 1" variant="danger" id="decline" @click="rechazarOrden(item.id)">Rechazar</b-button>
            <b-card-text class="mb-25"></b-card-text>
          </b-card-header>
          <a :href="type == 'Oportunity' ? '#' : '/work-order/' + item.id">
            <b-card-body>                    
              <!-- En proceso -->
              <div v-if="type == 'InProcess'">
                <span class="mr-2"><strong>Dirección de actuación :</strong>  {{item.cp_full_address}} </span>
                <b-card-text class="mb-25 text-truncate"> {{ item.title }} <br> <small><i>{{ types[item.type] }}</i></small></b-card-text>
              </div>
              <!-- Presupuestado -->
              <div v-else-if="type == 'Budgeted'">
                <h4 class="text-primary">Pendiente de aceptación de presupuesto</h4>
                <b-card-text class="mb-25"><strong>Último Presupuesto dado: </strong> : {{ item.last_budget ? item.last_budget.total : 'Sin presupuetos'  }}</b-card-text>
                <span class="mr-2"><strong>Dirección de actuación :</strong>  {{item.cp_full_address}} </span>
                <b-card-text class="mb-25 text-truncate">  {{ item.title }} <br> <small><i>{{ types[item.type] }}</i></small></b-card-text>
              </div>
              <!-- Completadas-->
              <div v-else-if="type == 'Completed'">             
                <span class="mr-2"><strong>Dirección de actuación :</strong>  {{item.cp_full_address}} </span>
                <b-card-text class="mb-25 text-truncate"> {{ item.title }} <br> <small><i>{{ types[item.type] }}</i></small>
                </b-card-text>                
              </div>
              <!-- Oportunidades -->
              <div v-else-if="type == 'Oportunity'">             
                <span class="mr-2"><strong>Dirección de actuación :</strong>  {{item.cp_full_address}} </span>
                <b-card-text class="mb-25 text-truncate">   {{ item.title }} <br> <small><i>{{ types[item.type] }}</i></small> </b-card-text>
              </div>
              <!-- Nulls -->
              <div v-else-if="type == 'Nulls'">
                <b-card-text class="mb-25"><strong>Último Presupuesto dado: </strong> :
                  {{ item.last_budget ? item.last_budget.total : 0 }}</b-card-text>
                <span class="mr-2"><strong>Dirección de actuación :</strong>  {{item.cp_full_address}} </span>
                <b-card-text class="mb-25 text-truncate"> {{ item.title }} <br> <small><i>{{ types[item.type] }}</i></small></b-card-text>
                </div>
              <!-- Liquidar -->
              <div v-else-if="type == 'Liquidar'">
                <b-card-body>
                  <b-card-text class="mb-25"><strong>Presupuesto dado: </strong> :
                    {{ item.accepted_budget ? item.accepted_budget.total : 'No aceptado'}}</b-card-text>
                  <span class="mr-2"><strong>Dirección de actuación :</strong> {{item.cp_full_address}} </span>
                  <b-card-text class="mb-25 text-truncate"> {{ item.title }} <br> <small><i>{{ types[item.type] }}</i></small></b-card-text>
                </b-card-body>
              </div>
              <div v-if="item.status != 1">
                <strong>Descripción OT:</strong>
                {{  item.description }}
              </div> 
              <b-card-text class="mb-25 text-truncate"><strong>Cobra:</strong> {{ arrChargedBy[item.charged_by] }}</b-card-text>
              <b-card-text v-if="item.biller > 0" class="mb-25 text-truncate"><strong>Factura:</strong> {{ arrBiller[item.biller] }}</b-card-text>
              <!-- <div v-if="item.notes.filter(note => note.role_id != 4 && new Date(note.updated_at) > new Date(item.updated_at)).length > 0">
                <feather-icon icon="MailIcon" style="margin-top:-2px" />&nbsp;
                <span class="notification-rounded green">{{ item.notes.filter(note => note.role_id != 4).length }}</span>
              </div> -->
              <!-- <div v-else-if="item.notes.filter(note => note.role_id != 4).length > 0"> -->
              <div v-if="item.notes.filter(note => note.ok_frontend != 1).length > 0">
                <feather-icon icon="MailIcon" style="margin-top:-2px" />&nbsp;
                <span class="notification-rounded green">{{ item.notes.filter(note => note.ok_frontend != 1).length }}</span>
              </div>
            </b-card-body>
          </a>
        </b-card>
      </b-col>
      <div class="text-center">
        <h4 v-if="list.length == 0" class="text-danger h4 text-center">¡NO HAY ÓRDENES DE TRABAJO EN ESTE APARTADO!</h4>
      </div>
    </b-row>
    <b-row style="flex-direction: row-reverse;">
      <b-button v-if="currentPage < lastPage" class="mr-1 btn-sm" variant="success" id="next" @click="searchWork(1)">Siguiente</b-button>
      <b-button v-else class="mr-1 btn-sm" variant="success" id="next" disabled>Siguiente</b-button>      
      <div style="margin-right:15px; margin-top:3px">Página {{ currentPage }} de {{ lastPage }}</div>
      <b-button v-if="currentPage > 1 " class="mr-1 btn-sm" variant="success" id="next" @click="searchWork(-1)">Anterior</b-button>
      <b-button v-else class="mr-1 btn-sm" variant="success" id="next" disabled >Anterior</b-button>      
    </b-row>
  </b-overlay>
  <b-modal id="rechazarOrden" title="Rechazar orden">
        <label>Motivo rechazo</label>     
        <b-form-textarea name="not_accepted" v-model="not_accepted"></b-form-textarea>            
          <template #modal-footer="{cancel}">
            <b-row class="w-100">
              <b-col md="4">
                <button class="btn btn-danger btn-sm btn-block mb-1" @click="changeStatus(refuse_order,0)">Rechazar</button>            
              </b-col>
              <b-col md="4">
                <button  class="btn btn-secondary btn-sm btn-block" data-dismiss="modal" @click="cancel()">Cancelar</button>
              </b-col>
            </b-row>
          </template>
      </b-modal>
</b-container>
</template>
<script>
import { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BRow, BCardHeader, BCard, BCardBody,BCardText, BButton, BCardTitle, BCol,BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex';
export default {
  props : ['type'],
  watch: { 
    type: function() { 
      this.show = true;
      this.search = '',
      this.list = [];
      this.$http.get("/api/getOrdersbyStatus?scope="+this.type+"&user_id=" + this.user.id).then((response) => {
        this.list = response.data['orders']['data'];
        this.currentPage = response.data['orders']['current_page'];
        this.lastPage = response.data['orders']['last_page'];
        this.status = response.data['status'];
        this.types = response.data['types'];      
        this.$forceUpdate();
        this.show = false;
      }); 
    }
  },   
  components: {VBModal, BForm, BFormInput, BFormTextarea, BContainer,BCardHeader, BCard, BRow, BCardBody,BCardText, BButton,BCardTitle, BCol,BOverlay},
  data() {
    return {
      show : false,
      status : [],
      currentPage : 1,
      lastPage : 1,
      types : [],
      list : [],
      order : [],
      refuse_order : null,
      not_accepted : null, 
      arrChargedBy : ["operario", "empresa", "servipro", "no especificado"],
      arrBiller : ["Pendiente especificar", "Servipro", "Empresa", "Operario"],
      search : ''
    }
  },
  created () {
    this.show = true;
    this.$http.get("/api/getOrdersbyStatus?scope="+this.type+"&user_id=" + this.user.id).then((response) => {
      this.list = response.data['orders']['data'];
      this.currentPage = response.data['orders']['current_page'];
      this.lastPage = response.data['orders']['last_page'];
      this.status = response.data['status'];
      this.types = response.data['types'];
      this.show = false;
    });   
  },
  methods: {
    searchWork (addPage = 0) {
      this.currentPage = Number(this.currentPage) + addPage;
      if(addPage == 0) this.currentPage = 1;
      window.scrollTo(0, 0);
      this.show = true;
      this.$http.get("/api/getOrdersbyStatus?scope="+this.type+"&user_id=" + this.user.id + "&search="+this.search + "&page="+this.currentPage).then((response) => {
        this.list = response.data['orders']['data'];
        this.currentPage = response.data['orders']['current_page'];
        this.lastPage = response.data['orders']['last_page'];
        this.status = response.data['status'];
        this.types = response.data['types'];
        this.show = false;
      }); 
    },
    searchReset() {
      this.search = '';
      this.searchWork();
    },
    changeStatus(id, status){
      let $parameters = {scope : this.type, tech : this.user.technician.id, work_order : id , status : status}
      if(!status){
        $parameters.not_accepted = this.not_accepted;
      }
      let that = this;
      this.$http.post("/api/changeStatus", $parameters).then((response) => {       
        if(status) {
          this.$router.push({ name: 'workorders' })
        }else {
          this.list = response.data['orders'];
          this.$bvModal.hide('rechazarOrden');
          this.$bvToast.toast('Orden rechazada', { variant: 'danger',noCloseButton : true });
        }
        // this.show = false;
      });
    },    

    // para modal de rechazar
    rechazarOrden(refuse_order){
      this.refuse_order= refuse_order;
      this.$bvModal.show('rechazarOrden');
    },    
    formatTime(date){
      let format_date = new Date(date).toLocaleDateString('es-es',{ year: 'numeric',month: '2-digit',day: '2-digit'}) 
      let format_time = new Date(date).toLocaleTimeString('es-es',{ hour : '2-digit', minute : '2-digit'});
      return  format_date + ' ( ' + format_time + ' ) '
    },
    formatDate(date){
      let format_date = new Date(date).toLocaleDateString('es-es',{ year: 'numeric',month: '2-digit',day: '2-digit'}) 
      return  format_date
    },
  },    computed: {
    ...mapState({
      user: state => state.auth.user,   
    }),
  }    
}
</script>
<style>
.notification-rounded {
  display: inline-block;
  width:25px;
  height: 25px;
  color: white;
  padding:5px;
  padding-top:3px;
  border-radius: 25px;
  text-align: center;
}
.green {
  background-color: green;
}

.grey {
  background-color: grey;
}
</style>